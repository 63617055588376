const myLocalStorage = {
    set(key: string, value: string, expire: number) {
        // const expireTime = new Date().getTime() + expire * 1000;
        localStorage.setItem(key, JSON.stringify({ value, expireTime: expire }));
    },
    get(key: string) {
        try {
            const item = localStorage.getItem(key);
            if (!item) {
                return null;
            }
            const { value, expireTime } = JSON.parse(item);
            if (new Date().getTime() > expireTime) {
                localStorage.removeItem(key);
                return null;
            }
            return value;
        } catch (err) {
            return null;
        }
    },
    remove(key: string) {
        localStorage.removeItem(key);
    }
};

export default myLocalStorage;