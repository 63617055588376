import SDK from "@uuoa/jssdk";
import { client } from "~/utils/ApiClient";
// https://open.api.sn.cn/
const baseUrl = "https://api.uupt.work"; //本地
const authUrl = "https://auth.uupt.work"; //本地
const APP_ID = "app161d6fd6490b9e";


export const getUserInfoByCode = () => {
    // 免登逻辑
    return new Promise((resolve, reject) => {
        SDK.user.requestAuthCode({
            onSuccess(res) {
                client.post(`/uuoa/login`, {
                    code: res.code,
                })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        console.log("some thing is wrong when get user info: ", err);
                        reject(false);
                    });
            },
        });
    })
}

export default function initSdk() {
    return new Promise((resolve, reject) => {
        // 获取sdk配置
        client.get(`${baseUrl}/open/apis/jssdk/client/init/${APP_ID}`, {
            url: location.origin,
        }, {
            headers: {
                deletePragma: true
            }
        }).then((res) => {
            const SDK_CONFIG = {
                ...res,
                jsApiList: ["request.auth.base"],
            };

            SDK_CONFIG.baseUrl = baseUrl;
            SDK_CONFIG.authUrl = authUrl;

            SDK.config(SDK_CONFIG);

            SDK.ready(() => {
                // 免登逻辑
                getUserInfoByCode().then((res) => {
                    resolve(res);
                })
                    .catch((err) => {
                        console.log("some thing is wrong when get user info: ", err);
                        reject(false);
                    });
            });

            SDK.error((err) => {
                console.log("sdk error", err.data);
                reject(false);
            });
        })
            .catch((err) => {
                console.log("sdk error", err);
                reject(false);
            });

    });
}
