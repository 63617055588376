/*
 * @Author: Zhangyj
 * @Date: 2022-07-04 17:24:04
 * @LastEditors: Zhangyj
 * @LastEditTime: 2022-07-05 14:45:40
 * @Description: Do not edit
 */
import * as React from "react";
import { client } from "~/utils/ApiClient";
import WebOfficeSDK from '~/utils/web-office-sdk-v1.1.19.es.js';

const Preview = (props) => {
    const { attachmentId } = props.match.params;
    const cardRef = React.useRef<HTMLDivElement>(null);
    const [sdk, setSdk] = React.useState(undefined);
    const [loading, setLoading] = React.useState(true);


    React.useEffect(() => {
        // console.log("preview useEffect----------------")
        if (!attachmentId) {
            setLoading(false);
            return;
        }
        client.post(`/attachment.info`, {
            id: attachmentId
        })
            .then(res => {
                const data = res.data;
                // console.log('atache res', data)
                const notInIframe = window.top == window.self

                const jssdk = WebOfficeSDK.config({
                    url: data.preview_url,
                    mount: cardRef.current,
                    simple: true,
                    wordOptions: {
                        isBestScale: notInIframe ? false : true,
                    },
                    pdfOptions: {
                        isBestScale: notInIframe ? false : true,
                    }
                })
                if (!jssdk) {
                    setLoading(false);
                    return;
                }
                setSdk(jssdk);
                jssdk.setToken({
                    token: data.token
                })

                jssdk.on('fileOpen', () => {
                    setLoading(false);
                });
            })
    }, []);

    return (
        <>
            {
                (loading || sdk) ?
                    <div ref={cardRef} style={{ height: '100%', overflow: 'hidden' }} /> :
                    <div style={{
                        height: '100%', display: 'flex',
                        color: '#9ba6b2', fontSize: 14,
                        alignItems: 'center', justifyContent: 'center', overflow: 'hidden'
                    }}>
                        文档暂无法预览
                    </div>
            }
        </>
    )
};

export default Preview;
