/*
 * @Author: Zhangyj
 * @Date: 2022-07-04 17:24:04
 * @LastEditors: Zhangyj
 * @LastEditTime: 2022-12-30 17:56:02
 * @Description: Do not edit
 */
import * as React from "react";
import { client } from "~/utils/ApiClient";
import styled from "styled-components";
import Text from "~/components/Text";

const Preview = (props) => {
    const { attachmentId, type } = props.match.params;
    const cardRef = React.useRef<HTMLDivElement>(null);
    const [loading, setLoading] = React.useState(true);
    const [err, setError] = React.useState(false);
    const [href, setHref] = React.useState('');
    const videoRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (!attachmentId || !type) return;
        setLoading(true)
        client.post(`/attachment.url`, {
            id: attachmentId
        })
            .then(res => {
                setHref(res.data.url)
            }).catch(err => {
                console.log('err')
            })
        window.addEventListener('resize', handleResize, false);
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);

    const handleResize = function (e) {
        handleLoad()
    }

    function handleLoad() {
        if (!videoRef.current) return;
        setLoading(false)

        setTimeout(() => {
            const width = videoRef.current?.clientWidth;
            const height = videoRef.current?.clientHeight;

            window.parent.postMessage({
                type: 'videoLoaded',
                id: attachmentId,
                width,
                height
            }, '*');
        })

    }
    function handleError(e) {
        setError(true)
    }

    return (
        <>
            {
                href ?
                    (
                        type == 1 ? (
                            <AudioBox>
                                {loading && <Loading>{err ? '资源加载出错了！' : '资源加载中...'}</Loading>}
                                <Audios
                                    controls
                                    onCanPlay={handleLoad}
                                    onError={handleError}
                                    controlslist="nodownload nofullscreen noremoteplayback">
                                    <source src={href} type="audio/mp3" />
                                </Audios>
                            </AudioBox>
                        ) : (
                            <VideoBox>
                                {loading && <Loading>{err ? '资源加载出错了！' : '资源加载中...'}</Loading>}
                                <Videos
                                    controls
                                    ref={videoRef}
                                    data-type="embed-video"
                                    raw-controls="true"
                                    controlslist="nodownload noremoteplayback"
                                    poster=""
                                    disablepictureinpicture=""
                                    webkit-playsinline="true"
                                    playsinline=""
                                    onCanPlay={handleLoad}
                                    onError={handleError}
                                    className="videos"
                                    preload="metadata">
                                    <source src={href} type="video/mp4" />
                                </Videos>
                            </VideoBox>
                        )
                    ) :
                    <Loading>资源加载中......</Loading>
            }
        </>
    )
};


const Loading = styled(Text)`
  position: absolute;
  width: 100%;
  height: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: ${(props) => props.theme.audioBg};
  z-index: 1;
  font-size: 13px;
  color:  ${(props) => props.theme.textTertiary};
`

const AudioBox = styled.div`
  position: relative;
  height: auto;
  background: ${(props) => props.theme.audioBg};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 0;
`
const VideoBox = styled.div`
  position: relative;
  height: auto;
  background: ${(props) => props.theme.videoBg};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 0;
`

const Audios = styled.audio`
  width: 100%;
  height: 54px;  
  outline: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`

const Videos = styled.video`
  width: 100%;
  // height: 408px;
  background: rgb(0, 0, 0);
  outline: none;
  border-radius: 6px;
`

export default Preview;
